import axios from "axios";

export const axiosGet = async (url) => {
    const response = await axios.get(process.env.REACT_APP_API_URL + url);
    return response.data;
}

export const axiosPost = async (url: string, body: any) => {
    const response = await axios.post((process.env.REACT_APP_API_URL + url), body);
    return response;
};