import {SearchOutlined} from '@ant-design/icons';
import {Form, Button, Input, InputNumber, Space, Table, Typography, Popconfirm, Select} from "antd";
import {useEffect, useState} from "react";
import {axiosGet, axiosPost} from "../functions/axiosUtil";
import {useRecoilState} from "recoil";
import {curTab, defaultAreaList} from "../functions/RecoilStatement";


interface EditableCellProps extends React.HTMLAttributes <HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: number;
    children: React.ReactNode;
}

interface List {
    key: number;
    campaignName: string;
    time: string;
    appName: string;
    targetPrice: number;
    budget: number;
}

const Target = ({comIdx, dateRange, dateModalVisible, setDateModalVisible, setOneRow, setSiteIdxCps}) => {

    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [clickedRow, setClickedRow] = useState('');
    const [currentTab, setCurrentTab] = useRecoilState(curTab);
    const [areaList, setAreaList] = useRecoilState(defaultAreaList);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('target eeffect ',comIdx);
        if (comIdx){
            getTargetList(comIdx, dateRange);
        }
    }, [comIdx]);

    const getTargetList = async (comIdx, dateRange) => {
        try {
            setLoading(true)
            let url = '/api/atom/target';
            url = url + '?companyId=' + comIdx;
            if (dateRange) {
                url = url + `&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
            }
            const site = await axiosGet(url);
            let index = 1;
            site?.data?.map(async (i, idx) => {
                i.key = index++;
                // i.time = [{"mon":[1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"tue":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"wed":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"thu":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"fri":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"sat":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]},{"sun":[1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]}];
                if (typeof i.time !== "string" ) {
                    let weekTimeArray = [];
                    for (const day of i.time) {
                        let dataObj = {}
                        let timeArr = [];
                        let dayKey = (Object.keys(day))[0];
                        let timeArray = day[dayKey];
                        let startTime = timeArray.indexOf(0);
                        timeArr.push(startTime);
                        let endTime = timeArray.lastIndexOf(0);
                        timeArr.push(endTime);
                        dataObj['day'] = dayKey;
                        dataObj['range'] = timeArr;
                        weekTimeArray.push(dataObj);
                    }
                    i.time = weekTimeArray;
                    return i;
                }
            });
            setData(site.data);
        } catch (e) {
            console.error(e)
            setData([]);
        } finally {
            setLoading(false);
        }
    }

    const setTarget = async (targetData) => {
        const postData = {
            tar_index: targetData.targetIndex,
            tar_price: targetData.targetPrice,
            cmp_index: targetData.campaignIndex,
            cmp_budget: targetData.budget,
        };
        const result = await axiosPost('/api/atom/target', postData);
        return result;
    }
    const getTime = async (array) => {
        const mondayTime = array[0].mon;
        const startTime = mondayTime.indexOf(1);
        const endTime = mondayTime.lastIndexOf(1);
        return `${startTime}~${endTime}`;
    }


    const container = {
        width: '97%',
        textAlign: 'center',
        margin: 'auto',
        paddingTop: '5px',
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys, selectedKeys, confirm, clearFilters,
                         }) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => {
                        handleReset(clearFilters)
                        handleSearch('', confirm, '')
                    }} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) => (record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : ''),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchInput(selectedKeys);
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const isEditing = (record) => {

        return record.key === editingKey;
    }
    const edit = (record: Partial<List> & { key: React.Key }) => {

        setClickedRow(record);
        form.setFieldsValue({
            targetPrice: '',
            budget: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                setEditingKey('');
                await setTarget({...item, ...row});
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    async function onCpnClick(data) {
        setSiteIdxCps(data);
        setCurrentTab('2');
        return undefined;
    }

    const updateTimeSetting = (rowData) => {
        // if (!dateModalVisible){
            console.log('updateTimeSetting')
            setDateModalVisible(true);
            setOneRow(rowData);
        // }
    }

    // const decodeWeekTimeArrayToObj = (wta) => {
    //     let weekTimeArray =[];
    //     for (const day of wta){
    //         let dataObj = {}
    //         let timeArr = [];
    //         let dayKey = (Object.keys(day))[0];
    //         let timeArray = day[dayKey];
    //         let startTime = timeArray.indexOf(0);
    //         timeArr.push(startTime);
    //         let endTime = timeArray.lastIndexOf(0);
    //         timeArr.push(endTime);
    //         dataObj['day'] = dayKey;
    //         dataObj['range'] = timeArr;
    //         weekTimeArray.push(dataObj);
    //     }
    //     return JSON.stringify(weekTimeArray);
    // }

    const getTimeSetCol = (record) => {

       return ( <Button
            onClick={() => updateTimeSetting(record)}
            style={{
                marginRight: 8,
            }}
        >
               {'detail'}
        </Button>
       );
    }

    const columns: any = [
        {
            title: 'n',
            dataIndex: 'key',
            key: 'key',
            width: 30,
        },
        {
            title: '앱',
            dataIndex: 'appName',
            key: 'appName',
            ...getColumnSearchProps('appName'),
            width: 400
        },
        {
            title: '캠페인(광고주)',
            dataIndex: 'campaignName',
            width: 200,
            key: 'campaignName',
            ...getColumnSearchProps('campaignName'),
            render: (_, record) => (
                <Typography.Link onClick={async () => await onCpnClick(record)}>
                    {record.campaignName}
                </Typography.Link>
            )
        },
        {
            title: '시간설정',
            dataIndex: 'time',
            key: 'time',
            ...getColumnSearchProps('time'),
            width: 80,
            render: (_, record) => getTimeSetCol(record)

        },
        {
            title: '타겟팅(eCPM$)',
            dataIndex: 'targetPrice',
            key: 'targetPrice',
            ...getColumnSearchProps('targetPrice'),
            editable: true,
            width: 110,
        },
        {
            title: '예산설정',
            dataIndex: 'budget',
            key: 'budget',
            ...getColumnSearchProps('budget'),
            editable: true,
            width: 110,
        },
        {
            title: 'Operation',
            dataIndex: 'Operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
            width: 130,
        },
        {
            title: '요청수',
            dataIndex: 'request',
            key: 'request',
            ...getColumnSearchProps('request'),
            align: 'right',
            width: 100,

        },
        {
            title: '노출수',
            dataIndex: 'impression',
            key: 'impression',
            ...getColumnSearchProps('impression'),
            align: 'right',
            width: 100,
        },
        {
            title: '클릭',
            dataIndex: 'click',
            key: 'click',
            ...getColumnSearchProps('click'),
            align: 'right',
            width: 100,
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            ...getColumnSearchProps('ctr'),
            render: (value: any, item: any, index: number) => (`${ typeof value == 'number' ? value?.toFixed(2) : 0} %`),
            align: 'right',
            width: 100,
        },
    ];

    const EditableCell: React.FC<EditableCellProps> = ({
                                                           editing,
                                                           dataIndex,
                                                           title,
                                                           inputType,
                                                           record,
                                                           index,
                                                           children,
                                                           ...restProps
                                                       }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const mergedColumns = columns.map(c => {
        if (!c.editable) {
            return c;
        }
        return {
            ...c,
            onCell: (record: List) => ({
                record,
                inputType: c.dataIndex === 'targetPrice' || c.dataIndex === 'budget' ? 'number' : 'text',
                dataIndex: c.dataIndex,
                title: c.title,
                editing: isEditing(record),
            }),
        }
    });

    return (
        <>
            <div style={container}>
                <Form form={form} component={false}>
                    <Table
                        loading={loading}
                        dataSource={data}
                        columns={mergedColumns}
                        scroll={{x: 2000, y: 500}}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}

                    />
                </Form>
            </div>
        </>
    )
}

export default Target;