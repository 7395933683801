import {
    Button,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Popover,
    Select,
    Space,
    Switch,
    Table,
    Tag,
    Typography
} from 'antd';

import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {defaultAreaList} from "../functions/RecoilStatement";
import {Link} from "react-router-dom";
import {axiosGet, axiosPost} from "../functions/axiosUtil";
import {SearchOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import AreaHistoryModal from "./AreaHistoryModal";

const {Text} = Typography;
const Area = ({aComIdx, dateRange, siteIdxCps ,setSiteIdxCps}) => {
    // const [areaList, setAreaList] = useRecoilState(defaultAreaList);
    const [areaList, setAreaList] = useState([]);

    // const [areaList2, setAreaList2] = useRecoilState(defaultAreaList2);

    const [editingKey, setEditingKey] = useState('');
    const [clickedRow, setClickedRow] = useState('');
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataObj, setDataObj] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOnReq, setIsOnReq] = useState(false);

    useEffect(() => {
    }, [siteIdxCps])

    useEffect( () => {
            getAllAreaData(aComIdx, dateRange);
    },[aComIdx, siteIdxCps])

    async function getAllAreaData(aComIdx, dateRange) {
        console.log('getAllAreaData');

        try{
            setLoading(true);
            console.log(`siteIdxCps = ${siteIdxCps}`)

            if (siteIdxCps !== 'none'){
                console.log('siteIdxCps !== none');
                let url = '/api/atom/inventory';
                url = url + '?siteIdx=' + siteIdxCps.siteIdxCps + '&index=' + siteIdxCps.targetIndex;
                if (dateRange[0] && dateRange[1]) {
                    url = url + `&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
                }
                const site = await axiosGet(url);
                let index = 1;
                site.data.map(async (i, idx) => {
                    i.key = index++;
                    i.isVisible = false;
                    // i.timeRange = await getTime(i.time);
                    return i;
                });
                // setSiteIdxCps('none');
                setAreaList(site.data);
            }else{
                let url = '/api/atom/inventory';
                url = url + '?companyId='+aComIdx;
                if (dateRange[0] && dateRange[1]) {
                    url = url + `&startDate=${dateRange[0]}&endDate=${dateRange[1]}`
                }
                const site = await axiosGet(url);
                let index = 1;
                site.data.map(async (i, idx) => {
                    i.key = index++;
                    i.isVisible = false;
                    // i.timeRange = await getTime(i.time);
                    return i;
                });
                setAreaList(site.data);
            }
        } catch (e) {
            console.error(e);
            setAreaList([]);
        } finally {
            setLoading(false);
        }
        return undefined;

    }

    const isEditing = (record) => {
        return record.key === editingKey;
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchInput(selectedKeys);
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys, selectedKeys, confirm, clearFilters,
                         }) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => {
                        handleReset(clearFilters)
                        handleSearch('', confirm, '')
                    }} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) => (record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : ''),
    });

    const edit = (record: Partial<List> & { key: React.Key }) => {
        setClickedRow(record);
        console.log('areaList = ', areaList);
        form.setFieldsValue({
            targetPrice: '',
            budget: '',
            ...record,
        });
        console.log('key = ', record.key);
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...areaList];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setAreaList(newData);
                setEditingKey('');
                await update({...item, ...row});
            } else {
                newData.push(row);
                setAreaList(newData);
                setEditingKey('');
                console.log('new data = ', row)
            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const update = (obj) => {
        console.log(obj);
        // setTarget(obj);
        updateAreaEcpm(obj);
    }

    async function setAreaState(data, state) { // areaIdx, targetIdx, status
        const myState = state ? 'Y' : 'N'
        const resultdata = await axiosGet(`/api/atom/change?areaidx=${data.areaIdx}&index=${data.targetIndex}&status=${myState}`);
        console.log('resultdata = ', resultdata);
        // setData(site.data);
    }

    async function updateAreaEcpm(data) {
        const postData = {
            price: data.targetPrice,
            areaidx: data.areaIdx,
            targetIndex: data.targetIndex,
        };
        const result = await axiosPost('/api/atom/modify/specific', postData);
        console.log('result = ', result);
    }

    const showModal = async (data) => {
        setDataObj(data);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'n',
            dataIndex: 'key',
            key: 'key',
            width: 30,
            // ...getColumnSearchProps('key'),
            // sorter: (a: any, b: any) => a.key - b.key,
        },
        {
            title: '영역',
            dataIndex: 'areaName',
            key: 'areaName',
            width: 400,
            ...getColumnSearchProps('areaName'),
            sorter: (a: any, b: any) => a.areaName.localeCompare(b.areaName),
            // render: (text) => <a>{text}</a>,
        },
        {
            title: '캠페인',
            dataIndex: 'campaignName',
            key: 'campaignName',
            ...getColumnSearchProps('campaignName'),
            sorter: (a: any, b: any) => a.campaignName.localeCompare(b.campaignName),
        },
        {
            title: '타겟팅',
            dataIndex: 'targetName',
            key: 'targetName',
            ...getColumnSearchProps('targetName'),
            sorter: (a: any, b: any) => a.targetName.localeCompare(b.targetName),
        },
        {
            title: '소재',
            dataIndex: 'itemGroupName',
            key: 'itemGroupName',
            ...getColumnSearchProps('itemGroupName'),
            sorter: (a: any, b: any) => a.itemGroupName.localeCompare(b.itemGroupName),
            render: (_, record) => (
                <a href={record.item} target="_blank">{record.itemGroupName}</a>
            )
        },
        {
            title: '영역 (eCPM)',
            dataIndex: 'targetPrice',
            key: 'targetPrice',
            sorter: (a: any, b: any) => a.targetPrice - b.targetPrice,
            editable: true
        },
        {
            title: 'Operation',
            dataIndex: 'Operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
        {
            title: 'State',
            key: 'targetIndex',
            render: (_, record) => (
                <Space size="middle">
                    <Switch defaultChecked={record.status === 'Y'} thisRecord={record} onChange={(check, e) => {
                        setAreaState(record, check)
                    }}/>
                </Space>
            ),
        },
        {
            title: 'history',
            dataIndex: 'history',
            key: 'history',
            render: (_, record) => {
                return (<>
                    <Button type="primary" onClick={() => showModal(record)}>
                        history
                    </Button>
                </>);
            }
        },
        {
            title: '요청수',
            dataIndex: 'request',
            key: 'request',
            ...getColumnSearchProps('request'),
            align: 'right',
        },
        {
            title: '노출수',
            dataIndex: 'impression',
            key: 'impression',
            ...getColumnSearchProps('impression'),
            align: 'right',
        },
        {
            title: '클릭',
            dataIndex: 'click',
            key: 'click',
            ...getColumnSearchProps('click'),
            align: 'right',
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            ...getColumnSearchProps('ctr'),
            render: (value: any, item: any, index: number) => (`${ typeof value == 'number' ? value?.toFixed(2) : 0} %`),
            align: 'right',
        },
    ];

    const container = {
        width: '97%',
        textAlign: 'center',
        margin: 'auto',
        paddingTop: '5px',
    }

    const EditableCell: React.FC<EditableCellProps> = ({
                                                           editing,
                                                           dataIndex,
                                                           title,
                                                           inputType,
                                                           record,
                                                           index,
                                                           children,
                                                           ...restProps
                                                       }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const mergedColumns = columns.map(c => {
        if (!c.editable) {
            return c;
        }
        return {
            ...c,
            onCell: (record: List) => ({
                record,
                inputType: c.dataIndex === 'targetPrice' ? 'number' : 'text',
                dataIndex: c.dataIndex,
                title: c.title,
                editing: isEditing(record),
            }),
        }
    });

    const [form] = Form.useForm();




    return (
        <>
            <div style={container}>
                <Form form={form} component={false}>
                    <Table
                        loading={loading}
                        columns={mergedColumns}
                        dataSource={areaList}
                        scroll={{ x: 2000, y: 500 }}
                        // onChange={(key, row) => {
                        // console.log(key);
                        // console.log(row)}}
                        components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                </Form>
                <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <AreaHistoryModal dataObj={dataObj} isModalVisible={isModalVisible} tarIdx={dataObj.targetIndex}/>
                </Modal>
            </div>
        </>
    );
}
export default Area;