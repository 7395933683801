import {Table} from "antd";
import {axiosGet} from "../functions/axiosUtil";
import {useEffect, useState} from "react";

const AreaHistoryModal = ({dataObj, isModalVisible, tarIdx}) => {

    const [areaHistory, setAreaHistory] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getHistoryData();
    }, [dataObj])

    const getHistoryData = async () => {
        setLoading(true);
        try {
            console.log(`/api/atom/area/history/ecpm?areaidx=${dataObj.areaIdx}&targetIndex=${tarIdx}`)
            const resultdata = await axiosGet(`/api/atom/area/history/ecpm?areaidx=${dataObj.areaIdx}&tar_idx=${tarIdx}`);
            let index = 0;
            resultdata.data?.map(i => i.key = index++);
            setAreaHistory(resultdata.data || []);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }

    }

    const columns = [
        {
            title: 'ecpm',
            align: 'center',
            width: 100,
            fixed: 'left',
            dataIndex: 'ecpm',
            key: 'ecpm'
        },
        {
            title: 'dateTime',
            align: 'center',
            width: 100,
            fixed: 'left',
            dataIndex: 'dateTime',
            key: 'dateTime'
        }
    ]

    return (
        <Table
            bordered
            dataSource={areaHistory || []}
            columns={columns}
            loading={loading}
        />
    );

}

export default AreaHistoryModal;