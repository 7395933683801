import React from 'react';
import {BrowserRouter, Route, Routes, Switch} from "react-router-dom";
import AdSetting from "../pages/AdSetting";
import Target from "../targeting/Target";


const RouteMapper = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<AdSetting/>}/>
            <Route exact path='/target' element={<Target/>}/>
            {/*<Route exact path='/area' element={<Area/>}/>*/}
        </Routes>
    </BrowserRouter>
)


export default RouteMapper;