import './App.css';
import 'antd/dist/antd.min.css';
import RouteMapper from "./route/RouteMapper";

function App() {
    return (
        <RouteMapper>
            <div className="App"/>
        </RouteMapper>
    )
}

export default App;