import 'antd/dist/antd.min.css';
import {Children, useEffect, useState} from "react";
import {Layout, Menu, DatePicker, Select, Space, Button} from "antd";
import {Tabs} from 'antd';
import {UploadOutlined, UserOutlined, VideoCameraOutlined} from "@ant-design/icons";
import './AdSetting.css';
import Area from "../campaign/Area";
import Target from "../targeting/Target";
import {axiosGet} from "../functions/axiosUtil";
import {RecoilRoot, useRecoilState, useRecoilValue} from "recoil";
import {curTab, defaultAreaList} from "../functions/RecoilStatement";
import {useQuery} from "../components/hooks/useQuery";
import {useNavigate} from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import moment from 'moment';
import DateRangePicker from "./DateRangePicker";

const {RangePicker} = DatePicker;

const {TabPane} = Tabs;
const {Header, Sider, Content} = Layout;

function AdSetting() {
    const query = useQuery();
    const [collapsed, setCollapsed] = useState(false);
    const [comList, setComList] = useState([]);
    const [aComIdx, setAComidx] = useState('');
    const [currentTab, setCurrentTab] = useRecoilState(curTab);
    const dateFormat = 'YYYY-MM-DD';
    const [oneRow, setOneRow] = useState('');
    const [siteIdxCps, setSiteIdxCps] = useState('none');

    useEffect(() => {
        // console.log('ad seeing sidTidxcps ',siteIdxCps);
    },[siteIdxCps])

    const onTabChange = (key) => {
        // console.log('onTabChange key = ',key);
        setSiteIdxCps('none');
        // console.log('siteIdxCps = ',siteIdxCps);
        setCurrentTab(`${key}`);
    };
    const handleChange = (value) => {
        setAComidx(value);
        // console.log('adsetting siteIdxCps = ',siteIdxCps);
        // setSiteIdxCps(false);
    }
    const getSiteList = async () => {
        const site = await axiosGet('/api/atom/list');
        site.data.map((i, idx) => i.key = idx);
        setComList(site.data);
    }
    useEffect((() => {
        const auth = query.get('auth');
        if (auth !== 'atomadmin_4rhqgkrl2sms') {
            setIsModalVisible(true);
        }
        getSiteList();
    }), [])
    const [isModalVisible, setIsModalVisible] = useState(false);
    let navigate = useNavigate();


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        navigate("/target");
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        navigate("/target");
    };

    const onTimeSelected = (val) => {
        console.log('onTimeSelected ',val);
    };

    const onTimeChanged = ([st, et]) => {
        console.log('onTimeChanged ',st.format('HH:mm:ss'));
        console.log('onTimeChanged ',et.format('HH:mm:ss'));


    }
    const marks = {
        0: '0°C',
        26: '26°C',
        37: '37°C',
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100°C</strong>,
        },
    };

    const [dateModalVisible, setDateModalVisible] = useState(false);
    // const [dateRange, setDateRange] = useState([moment().subtract(1,'month').format(dateFormat), moment().format(dateFormat)]) // startDate , endDate
    const [dateRange, setDateRange] = useState(['2022-05-06', '2022-06-06']) // startDate , endDate


    const showDateModal = () => {
        setDateModalVisible(true);
    };

    const handleDateOk = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        setDateModalVisible(false);
    };

    const handleDateCancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        setDateModalVisible(false);
    };

    const onDateChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setDateRange(dateStrings);//['2022-06-06', '' ]
        } else {
            console.log('Clear');
        }
    };

    const showTimeSettingModal = () => {
        console.log('false = ',dateModalVisible);
        setDateModalVisible(true);
    };

    const timeSettinghandleOk = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        setDateModalVisible(false);
    };

    const timeSettinghandleCancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        setDateModalVisible(false);
    };

    return (
        <Layout className="total-layout">
            {/*<Sider trigger={null} collapsible collapsed={collapsed}>*/}
            {/*    <div className="logo"/>*/}
            {/*    <Menu*/}
            {/*        theme="dark"*/}
            {/*        mode="inline"*/}
            {/*        defaultSelectedKeys={['1']}*/}
            {/*        items={[*/}
            {/*            {*/}
            {/*                key: '1',*/}
            {/*                icon: <UserOutlined/>,*/}
            {/*                label: 'ATOM ADMIN',*/}
            {/*            },*/}
            {/*        ]}*/}
            {/*    />*/}
            {/*</Sider>*/}
            <Layout className="site-layout">
                <Header
                    className="site-layout-haeder-background"
                    style={{
                        padding: 0,
                    }}
                >
                    {/*<Menu*/}
                    {/*    theme="dark"*/}
                    {/*    mode="inline"*/}
                    {/*    defaultSelectedKeys={['1']}*/}
                    {/*    items={[*/}
                    {/*        {*/}
                    {/*            key: '1',*/}
                    {/*            icon: <UserOutlined/>,*/}
                    {/*            label: 'ATOM ADMIN',*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*/>*/}
                    <span><UserOutlined/> ATOM ADMIN</span>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 2,
                        minHeight: 280,
                    }}
                >
                    매체 :
                    <Select
                        placeholder={'매체 선택..'}
                        showSearch={true}
                        optionFilterProp="children"
                        onChange={handleChange}
                        style={{width: 200}}
                    >
                        {comList?.map((item) => (
                            <Select.Option
                                key={item.index}
                                value={item.comIdx}
                            >
                                {item.publisher}
                            </Select.Option>
                        ))}
                    </Select>
                    {/*<RangePicker/>*/}
                    <Space direction="vertical" size={12}>
                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Month': [moment().subtract(1,'month').startOf('month'), moment().startOf('month')],
                            }}
                            onChange={onDateChange}
                        />
                    </Space>
                    <Tabs activeKey={currentTab} type='card' onTabClick={onTabChange} style={{
                        paddingTop: '10px'
                    }}>
                        <TabPane tab="CAMPAIGN" key='1' onC>
                            <Target comIdx={aComIdx} dateRange={dateRange} dateModalVisible={dateModalVisible} setDateModalVisible={setDateModalVisible} setOneRow={setOneRow} setSiteIdxCps={setSiteIdxCps}/>
                        </TabPane>
                        <TabPane tab="AREA" key='2'>
                            <Area aComIdx={aComIdx} dateRange={dateRange} siteIdxCps={siteIdxCps} setSiteIdxCps={setSiteIdxCps}/>
                        </TabPane>
                        {/*<TabPane tab="Tab 3" key="3">*/}
                        {/*    Content of Tab Pane 3*/}
                        {/*</TabPane>*/}
                    </Tabs>
                    {/*<RouteMapper>*/}
                    {/*    <div className="App"/>*/}
                    {/*</RouteMapper>*/}
                </Content>
            </Layout>
                <Button type="primary" onClick={showTimeSettingModal}>
                    Open Modal with customized button props
                </Button>
            <Modal
                width={420}
                title="Basic Modal"
                visible={dateModalVisible}
                onOk={timeSettinghandleOk}
                onCancel={timeSettinghandleCancel}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                centered={true}
            >
                <DateRangePicker oneRow={oneRow} setOneRow={setOneRow}/>
            </Modal>

            {process.env.NODE_ENV !== 'development' && (
                <Modal title="Not Authorized" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>this page is admin page. please authorize first</p>
                </Modal>
            )}
        </Layout>
    )
}

export default AdSetting;
