import {Button, Checkbox, Select, Slider} from "antd";
import {Option} from "antd/es/mentions";
import {useEffect, useState} from "react";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {axiosGet, axiosPost} from "../functions/axiosUtil";

const DateRangePicker = ({oneRow, setOneRow}) => {
    const [day, setDay] = useState('mon');
    const [prevOneRowTime, setPrevOneRowTime] = useState([]);

    useEffect(()=>{
        setCurrentSliderValue(oneRow?.time === 'all' ? [0, 23] : oneRow.time[0].range);

    },[oneRow] )

    useEffect(() => {
        if (oneRow.time !== 'all'){
            const index = oneRow.time.findIndex(i => i.day === day);
            setCurrentSliderValue(oneRow.time[index].range);
        }
    }, [day])

    let defaultData = [{
        day: "mon", range: [0, 23]
    }, {
        day: "tue", range: [0, 23]
    }, {
        day: "wed", range: [0, 23]
    }, {
        day: "thu", range: [0, 23]
    }, {
        day: "fri", range: [0, 23]
    }, {
        day: "sat", range: [0, 23]
    }, {
        day: "sun", range: [0, 23]
    }];

    const [currentSliderValue, setCurrentSliderValue] = useState(oneRow?.time === 'all' ? [0,23] : [0, 5]);
    const [disableSelectBox, setDisableSelectBox] = useState(false);
    const [disableSetAllBox, setDisableSetAllBox] = useState( oneRow?.time === 'all');


    const onChangeSlider = (value: number | [number, number]) => {
        setCurrentSliderValue(value);
        if (disableSelectBox  ) {
            for (const d of oneRow.time) {
                d.range = value;
            }
        }
    };

    const onAfterChange = (value: number | [number, number]) => {
        const index = oneRow.time.findIndex(i => i.day === day);
        oneRow.time[index].range = [...value];
    };

    const onChange = (value: string) => {
        const index = oneRow.time.findIndex(i => i.day === value);
        console.log('day = ', oneRow.time[index].day)
        console.log('range = ', oneRow.time[index].range)
        setDay(value);
        setCurrentSliderValue([...oneRow.time[index].range]);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const onApplyTarget = async () => {
        const result = [];
        for (let i = 0; i < oneRow.time.length; i++) {
            const temp = {};
            const time = [];
            for (let j = 0; j < 24; j++) {
                if (j < oneRow.time[i].range[0] || j >= oneRow.time[i].range[1]) {
                    time.push(1);
                } else {
                    time.push(0);
                }
            }
            temp[oneRow.time[i].day] = time;
            result.push({...temp});
        }
        console.log('result = ', result);
        console.log('stringify = ', JSON.stringify(result));// 이 데이터를 바로 보내면 됨.
        await updateWeekTime(oneRow, result);
    }

    const updateWeekTime = async (data, encodedWeekTime) => {
        let postData = {
            index: data.targetIndex,
            time: JSON.stringify(encodedWeekTime),
            isAll: disableSetAllBox
        };
        if (!disableSetAllBox){
            delete postData.isAll;
        }
        const result = await axiosPost('/api/atom/target/time', postData);
        console.log(result);
    }

    const onChangeCheckBox = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
        setDisableSelectBox(e.target.checked);
        if(e.target.checked){
            for (const d of oneRow.time) {
                d.range = currentSliderValue;
            }
        }
    };

    const onSetAll = (e) => {
        setDisableSetAllBox(e.target.checked);
        if (e.target.checked === false) {
            oneRow.time = defaultData;
        }
    }
    const marks = {
        0: '0시',
        3: '3시',
        6: '6시',
        9: '9시',
        12: '12시',
        15: '15시',
        18: '18시',
        21: '21시',
        23: '23시',
    };


    return (<>
        <div style={{width: '360px', height: '100px'}}>
            <div style={{display: 'flex', margin: '10px'}}>
                <Select
                    showSearch
                    placeholder="Select a Day"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    disabled={disableSelectBox}
                    defaultValue={"mon"}
                >
                    <Option value="mon">월</Option>
                    <Option value="tue">화</Option>
                    <Option value="wed">수</Option>
                    <Option value="thu">목</Option>
                    <Option value="fri">금</Option>
                    <Option value="sat">토</Option>
                    <Option value="sun">일</Option>
                </Select>
                <Checkbox style={{marginLeft: '20px'}} onChange={onChangeCheckBox} disabled={disableSetAllBox} checked={disableSelectBox}>일괄 적용</Checkbox>
                <Checkbox style={{marginLeft: '20px'}} onChange={onSetAll} checked={disableSetAllBox}>전체</Checkbox>
                <Button type="primary" style={{marginLeft: '20px'}} onClick={onApplyTarget}>Apply</Button>
            </div>
            <Slider
                range
                marks={marks}
                step={1}
                value={currentSliderValue}
                min={0}
                max={24}
                onChange={onChangeSlider}
                onAfterChange={onAfterChange}
                disabled={disableSetAllBox}
            />
        </div>
    </>)
}
export default DateRangePicker;