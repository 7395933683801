import {atom} from "recoil";

export const curTab = atom({
    key: 'curTab',
    default: '1',
});
export const defaultAreaList = atom({
    key: 'areaList',
    default: [],
})
